import React from 'react';
import {TimePicker, DatePicker, Space} from 'antd';
import moment from 'moment';

export default class DateTimePicker extends React.Component {
  render() {
    let {
      datetime,
      onChange = () => {},
      dateOnly = false,
      allowNull = false,
      ...params
    } = this.props;
    if (!allowNull && !datetime) {
      datetime = new Date();
    }

    let defaultValue = datetime ? moment(datetime) : null;

    return (
      <Space>
        <DatePicker
          defaultValue={defaultValue}
          onChange={(value) => {
            onChange(value ? value.toDate() : null);
          }}
          format={dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
          showTime={!dateOnly ? {defaultValue} : false}
          style={{
            paddingLeft: 10,
            width: 250,
            height: 30,
            borderRadius: 5,
          }}
          {...params}
        />
      </Space>
    );
  }
}
